import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import Lara from '../../assets/persona-lara.svg'
import Lais from '../../assets/persona-lais.svg'

import { Wrapper, Section, Row, Col, Title, ImageLara, Sub, TopicsWrapper, TopicsDesc, Separator } from './style'

import textEN from 'src/pages/seguranca/assets/data/translate/tips/textEN.json'
import textPT from 'src/pages/seguranca/assets/data/translate/tips/textPT.json'
import { ContentType } from 'src/pages/seguranca/assets/data/translate/tips/types'

interface IDicas {
  lang: string;
}

type HowToDoProps = {
  howToDo: {
    text: string;
  }[];
}

type AvoidProps = {
  avoid: {
    text: string;
  }[];
}

export const Tips = ({ lang }: IDicas) => {
  const { title, titleMD, altImageAlwaysDo, altImageAvoid, alwaysDo, whatYouShouldAvoid, howToDo, avoid }: ContentType = lang === 'pt' ? textPT : textEN

  return (
    <Wrapper>
      <Section className='container'>
        <Row className='row'>
          <Col className='col-12 col-md-9 text-md-center mx-md-auto'>
            <Title className='d-md-none'>{title}</Title>
            <Title className='d-none d-md-block'>{titleMD}</Title>
          </Col>
        </Row>
        <Row className='row'>
          <Col className='col-12 col-md-6 mx-auto text-center'>
            <ImageLara src={Lara} alt={altImageAlwaysDo} />
          </Col>
          <Col className='col-12 col-md-6 p-0'>
            <Sub>{alwaysDo}</Sub>
            {howToDo.map((item: HowToDoProps, index: number) => (
              <TopicsWrapper key={index}>
                <div>
                  <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                </div>
                <TopicsDesc>{item.text}</TopicsDesc>
              </TopicsWrapper>
            ))}
          </Col>
        </Row>
      </Section>
      <Separator className='container d-none d-md-block'><div /></Separator>
      <Section className='container'>
        <Row className='row'>
          <Col className='col-12 col-md-6 mx-auto text-center'>
            <ImageLara src={Lais} alt={altImageAvoid} />
          </Col>
          <Col className='col-12 col-md-6'>
            <Sub>{whatYouShouldAvoid}</Sub>
            {avoid.map((item: AvoidProps, index: number) => (
              <TopicsWrapper key={index}>
                <div>
                  <OrangeIcon size='MD' color='#F56A50' icon='exit' />
                </div>
                <TopicsDesc>{item.text}</TopicsDesc>
              </TopicsWrapper>
            ))}
          </Col>
        </Row>
      </Section>
    </Wrapper>
  )
}
