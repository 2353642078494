import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Wrapper = styled.section`
  background: ${grayscale[100]};
`

export const Section = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  background: ${grayscale[100]};

  @media ${device.tablet} {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const Row = styled.div`
  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
`

export const Col = styled.div``

export const Title = styled.h2`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #161616;

  margin-bottom: 51px;

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const ImageLara = styled.img`
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const Sub = styled.h3`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #161616;
  padding: 0;

  @media ${device.tablet} {
    text-align: left;
  }
`
export const TopicsWrapper = styled.div`
  display: flex;
  margin-top: 21px;
`

export const TopicsDesc = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[400]};
  margin-left: 16px;

  @media ${device.desktopLG} {
    margin-right: -16px;
  }
`

export const Separator = styled.div`
  height: 1px;
  padding: 0 24px;

  div {
    border: 1px dashed ${grayscale[200]};
  }
`
