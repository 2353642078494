import React from 'react'

import { Wrapper, Section, Row, Col, Title, Description } from './style'

// translate
import textEN from 'src/pages/seguranca/assets/data/translate/transacoesSegurasISafe/textEN.json'
import textPT from 'src/pages/seguranca/assets/data/translate/transacoesSegurasISafe/textPT.json'
import { ContentType } from 'src/pages/seguranca/assets/data/translate/transacoesSegurasISafe/types'
interface ITransacoesSeguras {
  lang: string;
}

export const TransacoesSegurasISafe = ({ lang }: ITransacoesSeguras) => {
  const { title, description }: ContentType = lang === 'pt' ? textPT : textEN

  return (
    <Wrapper>
      <Section className='container'>
        <Row className='row'>
          <Col className='d-none d-md-block col-md-6 col-xl-7' />
          <Col className='col-12 col-md-6 col-xl-5'>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Col>
        </Row>
      </Section>
    </Wrapper>
  )
}
