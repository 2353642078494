import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Hero } from 'src/pages/seguranca/sections/hero/_index'
import { TransacoesSegurasISafe } from 'src/pages/seguranca/sections/transacoesSegurasISafe/_index'
import { Golpes } from 'src/pages/seguranca/sections/golpes/_index'
import { Tips } from 'src/pages/seguranca/sections/tips/_index'
import { MaisInformacoes } from 'src/pages/seguranca/sections/maisInformacoes/_index'
import { CanaisOficiais } from 'src/pages/seguranca/sections/canaisOficiais/_index'
import EmpresasParceiras from 'src/pages/seguranca/sections/empresas-parceiras/_index'
import { Perguntas } from 'src/pages/seguranca/sections/perguntas/_index'

import pageContext from './pageContext.json'
import { Wrapper } from 'src/pages/seguranca/style'

const GetToKnowInter = () => {
  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <TransacoesSegurasISafe lang={lang} />
        <Golpes lang={lang} />
        <Tips lang={lang} />
        <MaisInformacoes lang={lang} />
        <CanaisOficiais lang={lang} />
        <Perguntas lang={lang} />
        <EmpresasParceiras lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default GetToKnowInter
